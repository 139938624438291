import React from 'react';

import Layout from '../../components/layout';
import Work from '../../components/work';
import SEO from '../../components/seo';

const IndexPage = () => (
  <Layout locale="pt-BR">
    <SEO
      lang="pt-BR"
      title="Trabalhos"
      description="Conheça o trabalho da artista carioca Katharina Welper: Mosaicos, Pinturas, Marcenaria e Bordados."
    />
    <Work locale="pt-BR" />
  </Layout>
);

export default IndexPage;
