import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import useCategories from '../hooks/useCategories';

const Work = ({ locale = 'en' }) => {
  const imagesData = useCategories();

  const gLocale = locale.replace('-', '');
  const baseUrl = locale === 'pt-BR' ? '/pt-BR/trabalhos/' : '/work/';

  const data = useStaticQuery(graphql`
    query {
      categories: allCategoriesJson(sort: { fields: [order], order: ASC }) {
        edges {
          node {
            id
            uuid
            ptBR {
              name
              slug
            }
            en {
              name
              slug
            }
          }
        }
      }
    }
  `);

  return (
    <div className="categories">
      {data.categories.edges.map(({ node }) => (
        <a
          className="category"
          href={`${baseUrl}${node[gLocale].slug}/`}
          key={node.id}
        >
          <div className="category__container">
            <div className="category__background">
              <Img
                fluid={
                  _.sample(imagesData[node.uuid].edges).node.childImageSharp
                    .thumb
                }
              />
            </div>
            {node[gLocale].name}
          </div>
        </a>
      ))}
    </div>
  );
};

Work.propTypes = {
  locale: PropTypes.string
};

export default Work;
